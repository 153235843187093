<template>
  <div>

    <v-card width="370" class="mx-auto mt-5" flat>

      <Errors v-bind:errors="dbErrors"/>

      <!-- Anzeige für sonstige Fehler.  Wird geprüft wenn From abgeschickt wird und dann ggf angezeigt-->
      <v-alert 
      v-if="errors.length"
      color="error"
      type="error">
        <div>
          <div v-for="error in errors" v-bind:key="error">{{error}}</div>
          Das Event wurde nicht geändert.
        </div>
      </v-alert>

      <!-- Anzeige für sich überschneidende Events. Wird geprüft wenn From abgeschickt wird und dann ggf angezeigt -->
      <v-alert 
      v-if="conflictingEvents.length"
      color="error"
      type="error">
        <div>
          Zu dieser Zeit ist der Raum schon belegt:
          <div v-for="conflict in this.conflictingEvents" v-bind:key="conflict.start">
            {{conflict.start}} - {{conflict.end}}: {{conflict.teacher}}
          </div>
          Das Event wurde nicht geändert.
        </div>
      </v-alert>

    <v-card-title>
      <h4>Nachhilfestunde bearbeiten</h4>
    </v-card-title>

    <v-card-actions class="mx-1 mt-n5">
      <v-btn 
      v-if="isSeries && isAdmin"
      color="secondary"
      depressed 
      x-small
      @click="redirectToEditSeries"
      >
      Serie ändern
      </v-btn>
      <v-btn 
      color="error"
      depressed 
      x-small
      @click="fetchEventData"
      >
      zurücksetzen
      </v-btn>
    </v-card-actions>

    <v-card-text>
      <v-form 
      @submit.prevent
      v-model="valid"
      ref="addEventForm">

        <!-- Datumsfeld -->
        <v-text-field 
        type="date" 
        label="Datum"
        v-model.trim="date"
        :rules="dateRule"
        required
        />

        <!-- Startzeit Feld -->
        <v-text-field 
        type="time"
        label="Begin"
        v-model.trim="start"
        :rules="timeRule"
        @input="setEndTime"
        required
        />

        <!-- Endzeit Feld -->
        <v-text-field 
        type="time"
        label="Ende"
        v-model.trim="end"
        :rules="timeRule"
        required
        />

        <!-- Lehrerfeld -->
        <v-autocomplete
        :items="this.teachers"
        item-text="name"
        return-object
        label="Lehrer"
        v-model.trim="teacher"
        outlined
        :rules="requiredRule"
        required
        />

        <!-- Student field -->
        <v-text-field
        label="Schüler"
        v-model.trim="student.name"
        readonly
        outlined
        :rules="requiredRule"
        required
        />

        <!-- Raumauswahl -->
        <v-select
        :items="this.rooms"
        item-text="name"
        return-object
        label="Raum"
        v-model="room"
        outlined
        :rules="requiredRule"
        required
        />

        <!-- Fachauswahl -->
        <v-combobox
        :items = "this.subjects"
        label="Fach"
        v-model.trim="subject"
        outlined
        :rules="requiredRule"
        required
        >
        </v-combobox>

      </v-form>

      <Warnings v-bind:warnings="warnings"/>
    </v-card-text>
  

    <v-card-actions >

      <v-btn 
      color="primary"
      depressed 
      block
      :disabled="!valid"
      @click="editEvent"
      >
      Einmalig ändern
      </v-btn>
    </v-card-actions>
    
    </v-card>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import {auth, eventsCollection, teachersCollection, studentsCollection} from '../firebase';
import {addMinutes, calculateTimeBetween, formatDate} from '@/assets/js/timeUtils.js';
import {checkForm, noConflicts} from '@/assets/js/addEvent.js';
import {getInsituteSettings} from '@/assets/js/dataUtils.js';
import Warnings from '@/components/Warnings.vue';
import Errors from '@/components/Errors.vue';


export default {
    name: 'EditEvent',

    props: ['eventId'],

    components: {
      Errors,
      Warnings,
    },

    data() {
        return {
          rooms: [],
          minEventDuration: 45,
          valid: false, //true if ':rules' are fullfilled in every field of the form. Only then button 'Hinzufügen' is clickable
          possiblyConflictingEvents: [],  //array of events that can possibly conflict with the new one. Got frome firestore
          conflictingEvents: [],          //array with actually conflicting events with the new one.
          errors: [], //list of other custom errors
          dbErrors: [],
          event: {},
          date: '',
          start: '',
          end: '',
          duration: null,
          teacher: '',
          teachers: [],
          student: '',
          students: [],
          room: {},
          subject: '',
          year: '',
          school: '', 
          isSeries: false,
          instituteId: '',
          warnings: [],

          /* the following are displayed as list to choose from in the form */
          subjects: [
            'Deutsch', 'Englisch', 'Mathe'
          ],

          /* rules for the form */
          requiredRule: [
            v => !!v || 'Pflichtfeld',
          ],
          timeRule: [ //checks if the input is a string of format 'hh:mm'
            v => /([0-1][0-9]|2[0-3]):[0-5]\d/.test(v) || "Gib eine gültige Uhrzeit ein."
          ],
          dateRule: [ //checks if the input is a string of format 'yyyy-mm-dd' and a valid date. Caution! does not test for leap years. The 29.02 is always valid.
            v => /\d{4}-(02-[0-2]\d|(01|03|05|07|08|10|12)-([0-2]\d|3[0-1])|(04|06|09|11)-([0-2]\d|30))/.test(v) || "Gib ein gültiges Datum im Format 'yyyy-mm-dd' an."
          ]
        }
    },

    watch: {

    /* If events is changed, the filled in Events will be newly calculated. This could happen eg when an other user changes an event */
    start: {
      immediate: false,
      handler: 'durationWasChanged'
    },
    end: {
      immediate: false,
      handler: 'durationWasChanged'
    }
    },

    computed: {
      ...mapState(['isAdmin']),
    },

    async created() {
      //get instituteId of currently logged in user
      this.instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;
      this.getSettings();
      this.getTeachers();
      this.fetchEventData();
    },

    methods: {

      /**
       * @description fetches the data of the event with the id from the route from firestore
       */
      async fetchEventData(){
        try {
          const eventDoc = await eventsCollection.doc(this.eventId).get();
          this.event = eventDoc.data();
          this.event.id = eventDoc.id;
          this.date = this.event.date;
          this.start = this.event.start;
          this.end = this.event.end;
          this.room = {'name': this.event.room, 'id': this.event.roomId};
          this.subject = this.event.subject;
          this.duration = calculateTimeBetween(eventDoc.data().start, eventDoc.data().end); //store the current duration to check if it was changed in durationWasChanged()

          //if the event is part of a series
          if(this.event.seriesReference != ''){
            this.isSeries = true; //make the button editSeries visible            
          }

          //get name of the teacher from teacherRef
          const teacherdoc = await this.event.teacherRef.get();
          if (teacherdoc.exists){
            let teacherName = teacherdoc.data().firstname + ' ' + teacherdoc.data().lastname;
            this.teacher = {name: teacherName, ref: this.event.teacherRef};
          } else {console.log('No such document!')}

          //get the name of the student from studentRef
          const studentDoc = await this.event.studentRef.get();
          if (studentDoc.exists){
            let studentName = studentDoc.data().firstname + ' ' + studentDoc.data().lastname;
            this.student = {
              name: studentName, 
              ref: this.event.studentRef, 
              id: studentDoc.id,
              year: studentDoc.data().year,
              school: studentDoc.data().schooltype,
            };
          } else {console.log('No such document!')}

        } catch {
          this.dbErrors.push({
            text: 'Die Nachhilfestunde konnte nicht aus der Datenbank geladen werden',
            type: 'firestore',
          })
          throw "Could not get document."
        }
      },

      getTeachers() {
        teachersCollection
          .where("instituteId", "==", this.instituteId)
          .orderBy('firstname')
          .get()
          .then(snapshot => {
            this.teachers = [];
            snapshot.forEach(doc => {
              let teacherName = doc.data().firstname + ' ' + doc.data().lastname
              let teacherRef = teachersCollection.doc(doc.id);
              this.teachers.push({name: teacherName, ref: teacherRef});
            })
          }).catch((error) => {
            console.log("Error getting documents: ", error);
            this.dbErrors.push({
              text: 'Die Lehrer:innen konnten nicht aus der Datenbank geladen werden.', 
              type:'firestore',
            })
          });
      },

      /**
       * @description gets the instiute settings (min Duration of an Event, rooms etc) from firestore and setts this.rooms and this.room
       */
      async getSettings(){
        try{
          //get institute Settings from currently logged in user
          let settings = await getInsituteSettings();
          this.minEventDuration = settings.minEventDuration;
          this.rooms = settings.rooms;
        } catch {
          this.dbErrors.push({
            text: 'Die Institutseinstellungen konnten nicht aus der Datenbank geladen werden.', 
            type:'firestore',
          })
        }
      },

      /**
     * @description prefills the end time to: start time + minutes that an event has minimum (this.minEnventDuration)
     */
      setEndTime(){
        this.end = addMinutes(this.start, this.duration);
      },


      /**
       * @description edits the new Event in firestore, if everything is valid
       */
      async editEvent() {

        /* check if all inputs are valid (this.valid) and all the custom rules are valid (this.checkForm) and if there are conflicting events */

        let formIsValid = false;
        [formIsValid, this.errors] = await checkForm(false, this.start, this.end);

        let noConflictsBool = false;
        let somebodyHasNotTimeErrors = [];
        [noConflictsBool, this.conflictingEvents, somebodyHasNotTimeErrors] = await noConflicts({date: this.date, start: this.start, end: this.end, room: this.room, teacher: this.teacher, student: this.student, id: this.eventId}, [this.eventId]); //is true if there are no conflicting events
        this.errors = this.errors.concat(somebodyHasNotTimeErrors);

        if(this.valid && formIsValid && noConflictsBool){

          try {
            /* the type of an event is 'event' except from online-events, where the type is 'online'. */
            var type = 'event';
            if(this.room.name == 'Online'){
              type = 'online';
            }

            /* Update the information in Firestore */
            await eventsCollection
            .doc(this.eventId)
            .update({
              date: this.date,
              start: this.start,
              end: this.end,
              teacherRef: this.teacher.ref,
              studentRef: this.student.ref,
              room: this.room.name,
              roomId: this.room.id,
              subject: this.subject,
              year: this.student.year,
              school: this.student.school,
              type: type,
            }).catch((error) => {
              console.log("Error updating documents: ", error);
              this.dbErrors.push({
                text: 'Die Nachhilfestunde konnte nicht geändert werden.', 
                type:'firestore',
              })
            });

            /* If the duration of the event was changed, add the difference to the lesson account */
            const minutesChanged = this.durationWasChanged(); //amount of minuntes that are less/more than before (event is 15 minutes shorter -> 15)
            if(minutesChanged) { //check if it was changed

              // add the hour to the hour account
              let today = formatDate(new Date().toISOString().substr(0,10));
              await this.$store.dispatch('editLessonAccount', {
                studentId: this.student.id, 
                minutes: minutesChanged, 
                notes: '[Die Stunde wurde am ' + today + ' verkürzt/verlängert.]', 
                date: this.date,
                subject: this.subject, 
                teacherName: this.teacher.name,
              })
            }

            /* save new route for redirection */
            let raumplanRoute = '/raumplan/'+ this.date + '/' + this.room.id

            /* clear the form */
            this.resetPrefill();

            /* redirekt to raumplan */
            this.$router.push(raumplanRoute);
          } catch (error) {
            console.log(error);
          }
        } else {
          /* scroll to beginning of page to display warning */
          window.scrollTo(0,0);
        }
      },

      /**
       * @description checks if the duration of the event was changed and displays warning if so
       */
      durationWasChanged() {
        let currentDuration = calculateTimeBetween(this.start, this.end);

        if(this.duration != currentDuration) {
          this.warnings = []; //remove old warning

          /* add new warning */
          const minutesChanged = this.duration - currentDuration;
          let warningMessage = 'Du möchtest die Dauer der Nachhilfestunde um ' + minutesChanged + ' Minuten verkürzen. Wir speichern das dann automatisch im Stundnenkonto für dich.';
          if(minutesChanged < 0) {
            warningMessage = 'Du möchtest die Dauer der Nachhilfestunde um ' + Math.abs(minutesChanged) + ' Minuten verlängern. Wir speichern das dann automatisch im Stundnenkonto für dich.';
          }

          this.warnings.push({
            text:  warningMessage, 
          });

          return minutesChanged;

        } else {
          //remove the warning.
          this.warnings = [];

          return false;
        }
      },

      /**
       * @description cleares the form and sets it to the standart values that are stored in the store
       */
      resetPrefill() {
        this.$refs.addEventForm.resetValidation()   //reset form validation

        /* reset custom validation */
        this.possiblyConflictingEvents = [];
        this.conflictingEvents = [];
        this.errors = [];
      },

      redirectToAddStudent(){
        this.$router.push('/addstudent');
      },

      redirectToEditSeries() {
        this.$router.push('/editseries/' + this.event.seriesReference.id);
      },
    }
}
</script>

<style>
/* workarround for bugfix: on iOS the cursor is invisible when first clicking into the field, see https://github.com/vuetifyjs/vuetify/issues/13628 */
.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>